import { AppActionTypes } from 'types/app/app';
import {
  ReservationSettingsState,
  ReservationSettingsActionTypes,
} from 'types/app/reservationSettings';
import {
  getSettingsCall,
  updateReservationSettingsCall,
} from 'api/app/restaurant/settings/calendar';
import { AppDispatch } from 'redux/store';

const reservationSettingsActions = {
  getReservationSettings() {
    return async (dispatch: AppDispatch) => {
      try {
        const response = await getSettingsCall();

        dispatch({
          type: ReservationSettingsActionTypes.Loaded,
          payload: response,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  updateReservationSettings(data: ReservationSettingsState) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: AppActionTypes.DisableSubmit });

      try {
        await updateReservationSettingsCall(data);
        dispatch({
          type: ReservationSettingsActionTypes.Loaded,
          payload: data,
        });
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: AppActionTypes.EnableSubmit });
      }
    };
  },
};

export default reservationSettingsActions;
