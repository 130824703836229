import { AppDispatch } from 'redux/store';
import { RootActionTypes } from 'types/app/root';
import { tableinAppInitialState } from 'redux/reducers';

const rootActions = {
  resetStoreToDefault: () => {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: RootActionTypes.ResetStoreToDefault,
        payload: tableinAppInitialState,
      });
    };
  },
};

export default rootActions;
