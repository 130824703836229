import { SnackbarKey } from 'notistack';
import {
  Notification,
  NotificationsCloseSnackbarAction,
  NotificationsEnqueueSnackbarAction,
  NotificationsRemoveSnackbarAction,
} from 'types/notifications';
import { NotificationTypes } from 'enums/notifications';

const notificationsActions = {
  enqueueSnackbar: (notification: Notification) => {
    return {
      type: NotificationTypes.Enqueued,
      payload: {
        notification: {
          ...notification,
          key:
            notification?.options?.key || ((new Date().getTime() + Math.random()) as SnackbarKey),
        },
      },
    } as NotificationsEnqueueSnackbarAction;
  },
  closeSnackbar: (key?: SnackbarKey) =>
    ({
      type: NotificationTypes.Closed,
      payload: {
        key,
        dismissAll: !key, // dismiss all if no key has been defined
      },
    } as NotificationsCloseSnackbarAction),
  removeSnackbar: (key: SnackbarKey) =>
    ({
      type: NotificationTypes.Removed,
      payload: {
        key,
      },
    } as NotificationsRemoveSnackbarAction),
};

export default notificationsActions;
