import { AppDispatch } from 'redux/store';
import { getSpecialOffersCall } from 'api/app/restaurant/specialOffers';
import { SpecialOffersActionTypes } from 'types/app/specialOffers';

const specialOffersActions = {
  getSpecialOffers() {
    return async (dispatch: AppDispatch) => {
      try {
        const response = await getSpecialOffersCall();
        dispatch({
          type: SpecialOffersActionTypes.UpdateSpecialOffers,
          payload: response,
        });

        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};

export default specialOffersActions;
