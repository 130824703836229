import { NotesState } from 'types/notes';

export const notesInitialState: NotesState = {
  isDrawerOpen: false,
  createNoteLoading: false,
  deleteNoteLoading: false,
  getNotesLoading: false,
  notes: [],
  selectedNote: null,
};
