import { initialState } from 'redux/reducers/index';
import {
  TimeManagementState,
  TimeManagementAction,
  TimeManagementActionTypes,
} from 'types/app/timeManagement';
import { map } from 'lodash';

export const timeManagementReducer = (
  state: TimeManagementState = initialState.app.settings.time_management,
  action: TimeManagementAction,
): TimeManagementState => {
  switch (action.type) {
    case TimeManagementActionTypes.UpdateDefaultBookingLength:
      return {
        ...state,
        default_booking_length: action.payload,
      };
    case TimeManagementActionTypes.CreateCustomBookingLength:
      return {
        ...state,
        custom_booking_length: [...state.custom_booking_length, action.payload],
      };
    case TimeManagementActionTypes.UpdateCustomBookingLength:
      return {
        ...state,
        custom_booking_length: action.payload,
      };
    case TimeManagementActionTypes.DeleteCustomBookingByIdLength:
      return {
        ...state,
        custom_booking_length: state.custom_booking_length.filter(
          (item) => item.id !== action.payload,
        ),
      };
    case TimeManagementActionTypes.UpdateCustomBookingByIdLength:
      return {
        ...state,
        custom_booking_length: map(state.custom_booking_length, (item) =>
          item.id === action.payload.id ? { id: action.payload.id, ...action.payload.data } : item,
        ),
      };
    default:
      return state;
  }
};
