import { initialState } from 'redux/reducers/index';
import { ProfileAction, ProfileActionTypes, ProfileState } from 'types/app/profile';

export const profileReducer = (
  state: ProfileState = initialState.app.profile,
  action: ProfileAction,
): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.Loaded:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
