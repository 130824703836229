import {
  getTimeAndLanguageCall,
  updateTimeAndLanguageCall,
} from 'api/app/restaurant/settings/time_and_language';
import { AppActionTypes } from 'types/app/app';
import { ITimeAndLanguageData, TimeAndLanguageActionTypes } from 'types/app/timeAndLanguage';
import { AppDispatch } from 'redux/store';

const timeAndLanguageActions = {
  getTimeAndLanguage() {
    return async (dispatch: AppDispatch) => {
      const response = await getTimeAndLanguageCall();
      dispatch({
        type: TimeAndLanguageActionTypes.Loaded,
        payload: response,
      });

      return response;
    };
  },
  updateTimeAndLanguage(data: ITimeAndLanguageData) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: AppActionTypes.DisableSubmit });
      try {
        await updateTimeAndLanguageCall(data);
        dispatch({
          type: TimeAndLanguageActionTypes.Loaded,
          payload: data,
        });
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: AppActionTypes.EnableSubmit });
      }
    };
  },
};

export default timeAndLanguageActions;
