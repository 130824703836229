import { NotificationsAction, NotificationsState } from 'types/notifications';
import { initialState } from './index';
import { NotificationTypes } from 'enums/notifications';

export function notificationsReducer(
  state: NotificationsState = initialState.notifications,
  action: NotificationsAction,
): NotificationsState {
  switch (action.type) {
    case NotificationTypes.Enqueued:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.payload.notification.key,
            ...action.payload.notification,
          },
        ],
      };

    case NotificationTypes.Closed:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.payload.dismissAll || notification.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      };

    case NotificationTypes.Removed:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.payload.key,
        ),
      };

    default:
      return state;
  }
}
