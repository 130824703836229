import { Dispatch } from 'redux';
import { AppState } from 'types';
import { createNoteCall, deleteNoteCall, getNotesCall, updateNoteCall } from 'api/notes';
import {
  NewNoteParams,
  Note,
  SetCreateNoteLoadingAction,
  SetDeleteNoteLoadingAction,
  SetGetNotesLoadingAction,
  SetIsDrawerOpenAction,
  SetNotesAction,
  SetSelectedNoteAction,
} from 'types/notes';
import notificationsActions from './notifications';
import { queryNotes } from 'app/data/dataservice';
import { prepareNotesFromLocalDatabase } from 'app/data/prepareNotesFromLocalDatabase';

const notesActions = {
  setIsDrawerOpen(isOpen: boolean) {
    return {
      type: 'Notes.setIsDrawerOpen',
      payload: { isOpen },
    } as SetIsDrawerOpenAction;
  },
  setAddNoteLoading(isLoading: boolean) {
    return {
      type: 'Notes.setCreateNoteLoading',
      payload: { isLoading },
    } as SetCreateNoteLoadingAction;
  },
  setDeleteNoteLoading(isLoading: boolean) {
    return {
      type: 'Notes.setDeleteNoteLoading',
      payload: { isLoading },
    } as SetDeleteNoteLoadingAction;
  },
  setGetNotesLoading(isLoading: boolean) {
    return {
      type: 'Notes.setGetNotesLoading',
      payload: { isLoading },
    } as SetGetNotesLoadingAction;
  },
  createNote(note: NewNoteParams) {
    let message: string;
    let variant: string;

    return (dispatch: Dispatch) => {
      dispatch<any>(notesActions.setAddNoteLoading(true));

      //Send full week if no weekdays specified
      if (note.days.length <= 0) {
        note.days = new Array(7).fill('').map((_, i) => i);
      }

      return createNoteCall(note)
        .then(() => {
          dispatch<any>(notesActions.setIsDrawerOpen(false));
          dispatch<any>(notesActions.getNotes());
          message = 'Note has been added successfuly.';
          variant = 'success';
        })
        .catch(() => {
          message = 'An error ocurred. Please try again later.';
          variant = 'error';
        })
        .finally(() => {
          dispatch<any>(notesActions.setAddNoteLoading(false));
          dispatch(
            notificationsActions.enqueueSnackbar({
              message,
              options: { variant },
            }),
          );
        });
    };
  },
  updateNote(id: string, note: NewNoteParams) {
    let message: string;
    let variant: string;

    return (dispatch: Dispatch) => {
      dispatch<any>(notesActions.setAddNoteLoading(true));

      //Send full week if no weekdays specified
      if (note.days.length <= 0) {
        note.days = new Array(7).fill('').map((_, i) => i);
      }

      return updateNoteCall(id, note)
        .then(() => {
          dispatch<any>(notesActions.setIsDrawerOpen(false));
          dispatch<any>(notesActions.getNotes());
          message = 'Note has been updated successfuly.';
          variant = 'success';
        })
        .catch(() => {
          message = 'An error ocurred. Please try again later.';
          variant = 'error';
        })
        .finally(() => {
          dispatch<any>(notesActions.setAddNoteLoading(false));
          dispatch<any>(notesActions.setSelectedNote(null));
          dispatch(
            notificationsActions.enqueueSnackbar({
              message,
              options: { variant },
            }),
          );
        });
    };
  },
  deleteNote(id: string, deleteAll: boolean, callback?: () => void) {
    let message: string;
    let variant: string;

    return (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      dispatch<any>(notesActions.setDeleteNoteLoading(true));

      deleteNoteCall(
        id,
        !deleteAll ? state.calendar.calendarSettings.date.format('YYYY-MM-DD') : null,
      )
        .then(() => {
          dispatch<any>(notesActions.getNotes());
          message = 'Note has been deleted successfuly.';
          variant = 'success';
        })
        .catch(() => {
          message = 'An error ocurred. Please try again later.';
          variant = 'error';
        })
        .finally(() => {
          dispatch<any>(notesActions.setDeleteNoteLoading(false));
          dispatch(
            notificationsActions.enqueueSnackbar({
              message,
              options: { variant },
            }),
          );
          if (state.notes.isDrawerOpen) {
            dispatch<any>(notesActions.setIsDrawerOpen(false));
          }
          if (!!callback) {
            callback();
          }
        });
    };
  },
  getNotes(_date?: string | null, _isOffline?: boolean | null) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      let formattedNotes: any = [];
      const calendarSettings = getState().calendar.calendarSettings;
      dispatch<any>(notesActions.setGetNotesLoading(true));
      const date = _date || calendarSettings.date.startOf('day').format('YYYY-MM-DD');

      if (_isOffline) {
        const dbNotes = await queryNotes();
        const preparedDbNotes = prepareNotesFromLocalDatabase(dbNotes);
        formattedNotes = preparedDbNotes.filter(
          (item: any) => item.start_date <= date && item.end_date >= date,
        );
        dispatch<any>(notesActions.setGetNotesLoading(false));
      } else {
        try {
          const notes: Note[] = await getNotesCall(date);
          dispatch<any>(notesActions.setGetNotesLoading(false));
          formattedNotes = Array.isArray(notes)
            ? notes
            : typeof notes === 'object' && notes !== null
            ? Object.values(notes)
            : [];
        } catch (err: any) {
          dispatch<any>(notesActions.setGetNotesLoading(false));
          if (!err['__CANCEL__']) {
            // dispatch(
            //   notificationsActions.enqueueSnackbar({
            //     message: 'Could not get notes. Please try again later.',
            //     options: { variant: 'error' },
            //   }),
            // );
          }
        }
      }

      dispatch({
        type: 'Notes.setNotes',
        payload: {
          notes: formattedNotes,
        },
      } as SetNotesAction);
    };
  },
  setSelectedNote(id: string | null = null) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const note = id !== null ? getState().notes.notes.find((n) => n.id === id) : null;
      dispatch({
        type: 'Notes.setSelectedNote',
        payload: {
          note: !!note ? { ...note, days: note.days.length < 7 ? note.days : [] } : null,
        },
      } as SetSelectedNoteAction);

      if (!!note) {
        dispatch<any>(notesActions.setIsDrawerOpen(true));
      }
    };
  },
};

export default notesActions;
