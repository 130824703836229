import { RootState } from '../store';
import { createSelector } from 'reselect';
import { Statuses, ReservationFilters, GuestOptionsFilters } from 'types/reservation';
import { Origin } from 'types/reservation';
import { snakeToCamelCase } from 'utils/str/snakeToCamelCase';
import { IReservation } from 'types/app/reservations';
import { ChannelStatistics } from 'app/components/NewSidebar/_components/SidebarStatistics/_config';

interface ReservationStatistics {
  [status: string]: {
    numberOfReservations: number;
    guests: number;
  };
}

export interface IReservationStatistic {
  numberOfReservations: number;
  guests: number;
}

export interface IReservationStatusStatistic {
  name: string;
  isCustom?: boolean;
  channel?: Origin;
  status: Statuses;
  statistics: IReservationStatistic;
}

interface OriginStatistics {
  phone: {
    count: number;
    guests: number;
  };
  online: {
    count: number;
    guests: number;
  };
}

export interface IChannelStatistic {
  name: string;
  isCustom?: boolean;
  channel?: string;
  status?: Statuses;
  statistics: Record<string, number | string>;
}

interface IStatistics {
  reservationStatuses: IReservationStatusStatistic[];
  totalGuests: number;
  totalArrivedGuests: number;
  totalToLeaveGuests: number;
  totalConfirmedGuests: number;
  channels: IChannelStatistic[];
}

interface TableToRoomMap {
  [key: string]: number;
}

const reservationList = (state: RootState) => state.app.reservations;
const preferences = (state: RootState) => state.app.preferences;
const times = (state: RootState) => state.app.app.opening_hour.time;
const filters = (state: RootState) => state.app.app.filters;
const tables = (state: RootState) => state.app.tables;
const settings = (state: RootState) => state.app.settings;

//TODO time ranges
export const selectFilteredReservationList = createSelector(
  [reservationList, times],
  (reservations, { start, end }) => {
    return reservations.filter((reservation) => {
      return reservation?.end_time > start && reservation?.start_time <= end;
    });
  },
);

export const selectFilteredReservationListByListPreferences = createSelector(
  [selectFilteredReservationList, preferences],
  (selectFilteredReservationList, preferences) => {
    const excludedStatuses: Statuses[] = [];

    if (!preferences.show_walk_in_on_lists) {
      excludedStatuses.push(Statuses.WalkIn);
    }

    return selectFilteredReservationList.filter((reservation) => {
      return !excludedStatuses.includes(reservation?.status);
    });
  },
);

export const selectCalendarReservationList = createSelector(
  [selectFilteredReservationList, preferences],
  (selectFilteredReservationList, preferences) => {
    const excludedStatuses: Statuses[] = [];

    if (!preferences.canceled) {
      excludedStatuses.push(Statuses.Cancelled);
    }

    if (!preferences.waiting_list) {
      excludedStatuses.push(Statuses.WaitingList);
    }

    if (preferences.no_show) {
      excludedStatuses.push(Statuses.NoShow);
    }

    return selectFilteredReservationList.filter((reservation) => {
      return !excludedStatuses.includes(reservation?.status);
    });
  },
);

export const selectReservationListByFilters = (reservationsList: IReservation[]) =>
  createSelector([filters, tables], (filters, tables) => {
    let reservations = [...reservationsList];
    if (filters.statuses.length > 0) {
      reservations = reservations.filter((reservation) =>
        filters.statuses.includes(reservation.status),
      );
    }

    if (filters.rooms.length > 0) {
      const tableToRoomMap: TableToRoomMap = tables.reduce<TableToRoomMap>((accumulator, table) => {
        accumulator[table.id] = table.room_id;
        return accumulator;
      }, {});

      reservations = reservations.filter((reservation) => {
        const reservationRoomIds = reservation.tables
          .map((tableId) => tableToRoomMap[tableId])
          .filter((roomId) => roomId !== undefined);

        return reservationRoomIds.some((roomId) => filters.rooms.includes(roomId));
      });
    }

    if (filters.special_offers.length > 0) {
      reservations = reservations.filter((reservation) => {
        return (
          reservation.special_offer && filters.special_offers.includes(reservation.special_offer.id)
        );
      });
    }

    if (filters.guests.length > 0) {
      reservations = reservations.filter((reservation) => {
        return filters.guests.some(
          (filterTag) => reservation?.client?.tags && reservation.client.tags.includes(filterTag),
        );
      });
    }

    if (filters.reservation_tags.length > 0) {
      reservations = reservations.filter((reservation) => {
        return filters.reservation_tags.some((filterTag) => reservation.tags?.includes(filterTag));
      });
    }

    if (filters.reservation.length > 0) {
      reservations = reservations.filter((reservation) => {
        const hasComments =
          filters.reservation.includes(ReservationFilters.WithComments) &&
          reservation.comments &&
          reservation.comments.length > 0;
        const hasReservationTag =
          filters.reservation.includes(ReservationFilters.WithReservationTag) &&
          reservation.tags &&
          reservation.tags.length > 0;
        //TODO
        // const hasFeedback =
        //   filters.reservation.includes(ReservationFilters.WithFeedback) &&
        //   reservation.feedback &&
        //   reservation.feedback.length > 0;

        return hasComments || hasReservationTag;
      });
    }

    if (filters.guests_options.length > 0) {
      if (filters.guests_options.includes(GuestOptionsFilters.GuestNotes)) {
        reservations = reservations.filter(
          (reservation) => reservation.client && reservation.client.note,
        );
      }

      if (filters.guests_options.includes(GuestOptionsFilters.GuestExtraInformation)) {
        reservations = reservations.filter(
          (reservation) =>
            reservation.client &&
            reservation.client.additional_info &&
            Object.values(reservation.client.additional_info).some((value) => value),
        );
      }

      if (filters.guests_options.includes(GuestOptionsFilters.Company)) {
        reservations = reservations.filter(
          (reservation) => reservation.client && reservation.client.company,
        );
      }
    }
    return reservations;
  });

const orderedStatuses: Statuses[] = [
  Statuses.WalkIn,
  Statuses.Cancelled,
  Statuses.NoShow,
  Statuses.WaitingList,
  Statuses.Pending,
];

const initStats = (statuses: Statuses[]): ReservationStatistics => {
  const initial: ReservationStatistics = {};
  statuses.forEach((status) => {
    initial[status] = { numberOfReservations: 0, guests: 0 };
  });
  return initial;
};

const initOriginStats = (): OriginStatistics => ({
  phone: { count: 0, guests: 0 },
  online: { count: 0, guests: 0 },
});

export const selectReservationStatistics = createSelector(
  [selectFilteredReservationList, settings, preferences],
  (filteredReservations, settings, preferences): IStatistics => {
    const validStatuses = [
      Statuses.Confirmed,
      Statuses.Arrived,
      Statuses.Pending,
      Statuses.WalkIn,
      Statuses.NoShow,
    ];

    const now = Math.floor(Date.now() / 1000) + settings.time_and_language.time_zone_offset * 60;
    const auto_arrival = preferences.auto_arrival;

    const excludedStatuses = [Statuses.Cancelled, Statuses.WaitingList];

    // Filter reservations to include only those with non-blocked, non-canceled statuses
    const newFilteredReservations = filteredReservations.filter((reservation) =>
      validStatuses.includes(reservation.status),
    );

    const excludedReservations = filteredReservations.filter((reservation) =>
      excludedStatuses.includes(reservation.status),
    );

    let totalGuests = 0;
    let totalConfirmedGuests = 0;
    let totalArrivedGuests = 0;
    let totalToLeaveGuests = 0;

    // Initialize statistics accumulator
    const validStats = newFilteredReservations.reduce((acc: ReservationStatistics, reservation) => {
      const { status, guests, start_time } = reservation;

      // Count total guests excluding canceled reservations
      totalGuests += guests;

      //Count arrived guests
      if (status === Statuses.Arrived) {
        totalArrivedGuests += guests;
      }

      //Count arrived guests
      if (status === Statuses.WalkIn) {
        totalArrivedGuests += guests;
      }

      if (status === Statuses.Confirmed) {
        if (now >= start_time && auto_arrival) {
          totalArrivedGuests += guests;
        } else {
          totalConfirmedGuests += guests;
          totalToLeaveGuests += guests;
        }
      }

      //Count left guests
      if (status === Statuses.NoShow) {
        totalToLeaveGuests += guests;
      }

      // Update individual status statistics
      if (acc[status]) {
        acc[status].numberOfReservations += 1;
        acc[status].guests += guests;
      }

      return acc;
    }, initStats(validStatuses));

    const excludedStats = excludedReservations.reduce((acc: ReservationStatistics, reservation) => {
      const { status, guests } = reservation;

      if (acc[status]) {
        acc[status].numberOfReservations += 1;
        acc[status].guests += guests;
      }

      return acc;
    }, initStats(excludedStatuses));

    const allStatuses = { numberOfReservations: 0, guests: 0 };

    const allReservationStats = { ...validStats, ...excludedStats };

    // Aggregate statistics for all statuses without cancelled
    Object.entries(allReservationStats).forEach(([status, stat]) => {
      if (status !== Statuses.Cancelled) {
        allStatuses.numberOfReservations += stat.numberOfReservations;
        allStatuses.guests += stat.guests;
      }
    });

    const allStatusesStatistic = {
      name: 'allStatusesStatistics',
      status: Statuses.Confirmed,
      statistics: allStatuses,
    };

    const reservationStatuses: IReservationStatusStatistic[] = [
      allStatusesStatistic,
      ...orderedStatuses.map((status) => ({
        name: `${snakeToCamelCase(status)}Statistics`,
        status,
        statistics: allReservationStats[status],
      })),
    ];

    const origins = newFilteredReservations.reduce((acc: OriginStatistics, reservation) => {
      const { origin, guests } = reservation;

      if (origin === Origin.Phone) {
        acc.phone.count += 1;
        acc.phone.guests += guests;
      } else if (origin === Origin.Online) {
        acc.online.count += 1;
        acc.online.guests += guests;
      }

      return acc;
    }, initOriginStats());

    return {
      totalGuests,
      totalArrivedGuests,
      totalToLeaveGuests,
      totalConfirmedGuests,
      reservationStatuses,
      channels: [
        {
          name: 'googleStatistics',
          channel: ChannelStatistics.Google,
          isCustom: true,
          statistics: {
            text: 'comingSoon',
          },
        },
        {
          name: 'facebookStatistics',
          isCustom: true,
          channel: ChannelStatistics.Facebook,
          statistics: {
            text: 'comingSoon',
          },
        },
        {
          name: 'instagramStatistics',
          isCustom: true,
          channel: ChannelStatistics.Instagram,
          statistics: {
            text: 'comingSoon',
          },
        },
        {
          name: 'phoneStatistics',
          channel: Origin.Phone,
          statistics: {
            count: origins.phone.count,
            guests: origins.phone.guests,
          },
        },
        {
          name: 'onlineStatistics',
          channel: Origin.Online,
          statistics: {
            count: origins.online.count,
            guests: origins.online.guests,
          },
        },
        {
          name: 'customStatistics',
          isCustom: true,
          channel: ChannelStatistics.Custom,
          statistics: {
            text: 'comingSoon',
          },
        },
      ],
    };
  },
);
