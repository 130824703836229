import { AppDispatch } from 'redux/store';
import { getTablesCall } from 'api/app/restaurant/roomAndTable';
import { TablesActionTypes } from 'types/app/tables';

const tablesActions = {
  getTables() {
    return async (dispatch: AppDispatch) => {
      const response = await getTablesCall();
      dispatch({
        type: TablesActionTypes.UpdateTables,
        payload: response,
      });

      return response;
    };
  },
};

export default tablesActions;
