import { initialState } from 'redux/reducers/index';
import {
  PreferencesActions,
  PreferencesActionTypes,
  PreferencesState,
} from 'types/app/preferences';

export const preferencesReducer = (
  state: PreferencesState = initialState.app.preferences,
  action: PreferencesActions,
): PreferencesState => {
  switch (action.type) {
    case PreferencesActionTypes.SetDefaultPreferences:
      return {
        ...state,
        ...action.payload,
      };
    case PreferencesActionTypes.UpdatePreference:
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    default:
      return state;
  }
};
