import { CalendarDisplay, ICalendarDisplay } from 'types/calendar';
import moment from 'moment-timezone';
import { CalendarState } from 'types/calendar';
import constants from 'utils/storage/constants';

export const calendarInitialState: CalendarState = {
  locale: '',
  fullBookingModalOpen: false,
  fullBookingModalIsLoading: false,
  reservationsIsLoading: false,
  reservationsIsNextPageLoading: false,
  extendedReservationsIsLoading: false,
  searchOpen: false,
  sidebarOpen: JSON.parse(localStorage.getItem(constants.VARIABLES.tableinSidebarOpen) || 'true'),
  calendarDisplay:
    CalendarDisplay[
      (localStorage.getItem(
        constants.VARIABLES.tableinCalendarDisplay,
      ) as keyof ICalendarDisplay) || CalendarDisplay.Fixed
    ],
  roomsAndTables: [],
  rooms: [],
  reservationsSettings: {
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
    page: 1,
    pagesCount: 1,
  },
  reservations: [],
  today: moment(),
  lateCancellationNoShowPayment: {
    askForPaymentModalIsOpen: false,
    paymentModalIsOpen: false,
  },
  restaurant: {
    settings: {
      open_hours: {},
      phone_required: false,
      default_language: 'en',
      timezone: 'Europe/Vilnius',
      increment: 30,
      date_format_key: 'ymd',
      hide_no_show: false,
      list_show_cancelled: false,
      list_show_walk_ins: false,
      initial_reservation_badge: false,
      exclusive_days: [],
      staff_summary: 0,
      first_day_of_week: 0,
      sms_notify_status: false,
      sms_notify_default_on: false,
      sms_notify_client_table_ready: false,
      auto_arrival_disabled: false,
      show_old_calendar: false,
      show_big_spender_tag: false,
      default_booking_length: 120,
    },
    address: {
      country: 'LT',
    },
    cumoWidgetTimes: [],
    cumoWidgetSpecificTimes: {
      times: {},
    },
    waiters: [],
  },
  calendarSettings: {
    date: moment(),
    startTime: moment(),
    endTime: moment(),
    closed: false,
    controlsSettings: {
      selectedDateIndex: 0,
      startDateFactor: 0,
    },
  },
  reservationListSettings: {
    startDate: null,
    endDate: null,
    advancedSearch: {
      isLoading: false,
      isNextPageLoading: false,
      params: null,
      results: [],
      page: 1,
      pagesCount: 1,
    },
  },
  reservationListStatistics: {
    guestsToday: 0,
    walkIns: 0,
    reservations: 0,
  },
  failedReservationsList: [],
  exportListToCsv: false,
};
