import { getConfig } from 'api/app/config';
import { ConfigActionTypes } from 'types/app/config';
import { AppDispatch } from 'redux/store';

const configActions = {
  getAppConfig() {
    return async (dispatch: AppDispatch) => {
      const response = await getConfig();
      dispatch({
        type: ConfigActionTypes.Loaded,
        payload: response,
      });
    };
  },
};

export default configActions;
