import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { TableManagementStatuses } from 'app/pages/RestaurantSettings/TableManagement/_config';

const tables = (state: RootState) => state.app.tables;
const rooms = (state: RootState) => state.app.rooms;

const layouts = (state: RootState) => state.app.settings.table_management.layouts;

export const selectRoomsByLayoutId = (id: number) =>
  createSelector([rooms], (rooms) => rooms.filter((room) => room.layout_id === id));

export const selectRoomOptionsByLayoutId = (id: number) =>
  createSelector([rooms], (rooms) =>
    rooms
      .filter((room) => room.layout_id === id)
      .map(({ id, name }) => ({ label: name, value: id })),
  );

export const selectRoomOptionsWithLayoutName = createSelector([rooms, layouts], (rooms, layouts) =>
  rooms.map((room) => {
    const layout = layouts.find((l) => l.id === room.layout_id);
    const { id, name } = room;
    return {
      value: id,
      label: `${layout?.name}: ${name}`,
    };
  }),
);

export const selectLayoutOptions = createSelector([layouts], (layouts) =>
  layouts.map(({ id, name }) => ({ label: name, value: id })),
);

export const selectTablesByRoomId = (id: number) =>
  createSelector([tables], (tables) => tables.filter((table) => table.room_id === id));

export const getLastTableByRoomId = (id: number) =>
  createSelector([tables], (tables) => tables.filter((table) => table.room_id === id).at(-1));

export const getLastRoomByLayoutId = (id: number) =>
  createSelector([rooms], (rooms) => rooms.filter((room) => room.layout_id === id).at(-1));

export const getLastLayoutElement = createSelector([layouts], (layouts) => layouts.at(-1));

export const selectLayoutById = (id: number) =>
  createSelector([layouts], (layouts) => layouts.find((layout) => layout.id === id));

export const selectTablesByIds = (ids: number[]) =>
  createSelector([tables], (tables) => tables.filter((table) => ids?.includes(table.id)));

export const selectActiveRooms = createSelector([rooms], (rooms) =>
  rooms.filter((room) => room.status === TableManagementStatuses.Active),
);

export const selectActiveTables = createSelector([tables], (tables) =>
  tables.filter((table) => table.status === TableManagementStatuses.Active),
);

export const orderRoomsByArrayOfId = (ids: number[], layoutId: number) =>
  createSelector([rooms], (rooms) => {
    if (ids.length === 0) {
      return rooms.filter((room) => room.layout_id === layoutId);
    }

    const orderMap: Map<number, number> = new Map(ids.map((id, index) => [id, index]));

    return rooms
      .filter((room) => room.layout_id === layoutId)
      .sort((a, b) => {
        const indexA = orderMap.get(Number(a.id)) ?? -1;
        const indexB = orderMap.get(Number(b.id)) ?? -1;
        return indexA - indexB;
      });
  });
