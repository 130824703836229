import { NotificationSettingsActionTypes } from 'types/app/notificationSettings';

import { AppDispatch } from 'redux/store';
import { getNotificationSMSSettingsCall } from 'api/app/restaurant/settings/notification';

const notificationSettingsActions = {
  getNotificationSMSSettings() {
    return async (dispatch: AppDispatch) => {
      try {
        const response = await getNotificationSMSSettingsCall();

        dispatch({
          type: NotificationSettingsActionTypes.Loaded,
          payload: response,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};

export default notificationSettingsActions;
