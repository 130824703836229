import { initialState } from 'redux/reducers/index';
import {
  TableManagementAction,
  TableManagementActionTypes,
  TableManagementState,
} from 'types/app/tableManagement';

export const tableManagementReducer = (
  state: TableManagementState = initialState.app.settings.table_management,
  action: TableManagementAction,
): TableManagementState => {
  switch (action.type) {
    case TableManagementActionTypes.GetRestaurantLayouts:
      return {
        ...state,
        layouts: action.payload,
      };
    case TableManagementActionTypes.GetTableGroups:
      return {
        ...state,
        groups: action.payload,
      };
    // case TableManagementActionTypes.CreateRestaurantLayout:
    //   return {
    //     ...state,
    //     // layouts: [...state.layouts, action.payload],
    //   };
    // case TableManagementActionTypes.UpdateRestaurantLayout:
    //   return {
    //     ...state,
    //     // layouts: map(state.layouts, (item) =>
    //     //   item.id === action.payload.id ? action.payload : item,
    //     // ),
    //   };
    // case TableManagementActionTypes.DeleteRestaurantLayout:
    //   return {
    //     ...state,
    //     // layouts: state.layouts.filter((item) => item.id !== action.payload),
    //   };
    // case TableManagementActionTypes.GetRestaurantLayoutWithRooms: {
    //   // const updatedLayouts = state.layouts.map((item) =>
    //   //   item.id === action.payload.id ? action.payload : item,
    //   // );
    //   return {
    //     ...state,
    //     // layouts: updatedLayouts,
    //   };
    // }
    // case TableManagementActionTypes.UpdateRestaurantLayouts:
    //   return {
    //     ...state,
    //     // layouts: unionBy(action.payload, state.layouts, 'id'),
    //   };
    // case TableManagementActionTypes.CreateRestaurantRoom:
    //   return {
    //     ...state,
    //     // layouts: unionBy(action.payload, state.layouts, 'id'),
    //   };
    // case TableManagementActionTypes.CreateRestaurantTable:
    //   return {
    //     ...state,
    //     // layouts: unionBy(action.payload, state.layouts, 'id'),
    //   };
    // case TableManagementActionTypes.UpdateRestaurantTable:
    //   return {
    //     ...state,
    //     // layouts: unionBy(action.payload, state.layouts, 'id'),
    //   };
    default:
      return state;
  }
};
