import { INotificationSettingsData, NotificationStatus } from 'types/app/notificationSettings';

export const notificationSettingsInitialState: INotificationSettingsData = {
  sms: {
    status: NotificationStatus.INACTIVE,
    sender_name: '',
    client_notify_types: {
      confirmation: false,
      reminder: false,
      feedback: false,
      no_show: false,
      table_ready: false,
    },
    staff_notify_types: {
      reservation_received: false,
      reservation_cancelled: false,
    },
  },
};
