import { ProfileState } from 'types/app/profile';
import { UserRoles, UserStatuses } from 'types/app/users';

export const profileInitialState: ProfileState = {
  id: 0,
  name: '',
  email: '',
  status: UserStatuses.Active,
  role: UserRoles.Owner,
  permissions: [],
  restaurants: [],
  waiters: [],
  drupal_roles: [],
  drupal_permissions: {},
};
