export const authInitialState = {
  userSettings: {
    roles: [],
    permissionToDelete: false,
    readOnly: false,
    activeLicense: null,
    permissions: {
      accessManagerPages: false,
      accessUserHasStaffPermissions: false,
      accessDashboard: false,
      accessRestaurantSubManager: false,
      accessWebAdmin: false,
      restaurantCanCreateNewRestaurant: false,
      accessSettingsPages: false,
      accessHubspotChat: false,
    },
    uid: 0,
  },
};
