import { initialState } from 'redux/reducers/index';
import {
  ReservationSettingsAction,
  ReservationSettingsActionTypes,
  ReservationSettingsState,
} from 'types/app/reservationSettings';

export const reservationSettingsReducer = (
  state: ReservationSettingsState = initialState.app.settings.reservation,
  action: ReservationSettingsAction,
): ReservationSettingsState => {
  switch (action.type) {
    case ReservationSettingsActionTypes.Loaded:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
