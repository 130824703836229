export const muiInputBaseRoot = {
  '& .MuiInputBase-root': {
    backgroundColor: 'brandWhiteDark',
  },
};

export const muiInputWithBorder = {
  '& fieldset': {
    borderColor: 'borderColor',
  },
  '&:hover fieldset': {
    borderColor: 'borderColor',
  },
  '&.Mui-focused fieldset': {
    borderColor: 'borderColor',
  },
};
