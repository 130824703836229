import { AppActionTypes } from 'types/app/app';
import { getProfileCall, updateProfileCall } from 'api/app/account/profile';
import { ProfileActionTypes } from 'types/app/profile';
import { AppDispatch } from 'redux/store';

const profileActions = {
  getProfile() {
    return async (dispatch: AppDispatch) => {
      try {
        const response = await getProfileCall();
        dispatch({
          type: ProfileActionTypes.Loaded,
          payload: response,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  updateProfile(data: any) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: AppActionTypes.DisableSubmit });
      try {
        await updateProfileCall(data);
      } catch (error) {
        throw error;
      } finally {
        dispatch({ type: AppActionTypes.EnableSubmit });
      }
    };
  },
};

export default profileActions;
