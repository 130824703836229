import { initialState } from 'redux/reducers/index';
import { TablesAction, TablesActionTypes, TablesState } from 'types/app/tables';

export const tablesReducer = (
  state: TablesState = initialState.app.tables,
  action: TablesAction,
): TablesState => {
  switch (action.type) {
    case TablesActionTypes.UpdateTables:
      return action.payload.filter((table) => table.status);
    default:
      return state;
  }
};
