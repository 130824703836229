import { FiltersState } from 'types/filters';

export const filtersInitialState: FiltersState = {
  selectedStatuses: [],
  dinningRooms: [],
  options: [],
  tag: null,
  selectedHours: {
    from: null,
    to: null,
    includeOngoing: false,
  },
  sort: {
    option: 'visit_time',
    direction: 'asc',
  },
};
