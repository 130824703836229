import { FloorPlanState } from 'types/floorPlan';

export const floorPlanInitialState: FloorPlanState = {
  isLoading: false,
  restaurantId: null,
  rooms: [],
  tables: [],
  walls: [],
  texts: [],
};
