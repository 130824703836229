import { initialState } from 'redux/reducers/index';
import { UsersAction, UsersActionTypes, UsersState } from 'types/app/users';

export const usersReducer = (
  state: UsersState = initialState.app.settings.users,
  action: UsersAction,
): UsersState => {
  switch (action.type) {
    case UsersActionTypes.Loaded:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
