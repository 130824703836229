import { initialState } from 'redux/reducers/index';
import {
  OpeningHoursAction,
  OpeningHoursActionTypes,
  OpeningHoursState,
} from 'types/app/openingHours';

export const openingHoursReducer = (
  state: OpeningHoursState = initialState.app.settings.opening_hours,
  action: OpeningHoursAction,
): OpeningHoursState => {
  switch (action.type) {
    case OpeningHoursActionTypes.Loaded:
      return action.payload;
    default:
      return state;
  }
};
