import { PreferencesState } from 'types/app/preferences';

export const preferencesInitialState: PreferencesState = {
  auto_arrival: true,
  original_time: true,
  waiting_list: true,
  show_walk_in_on_lists: true,
  show_sidebars: true,
  show_calendar_list_tags: true,
};
