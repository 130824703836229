import { initialState } from 'redux/reducers/index';
import {
  ITimeAndLanguageData,
  TimeAndLanguageAction,
  TimeAndLanguageActionTypes,
} from 'types/app/timeAndLanguage';

export const timeAndLanguageReducer = (
  state: ITimeAndLanguageData = initialState.app.settings.time_and_language,
  action: TimeAndLanguageAction,
): ITimeAndLanguageData => {
  switch (action.type) {
    case TimeAndLanguageActionTypes.Loaded:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
