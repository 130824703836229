import { initialState } from 'redux/reducers/index';
import { LoadingActions, LoadingActionTypes, LoadingState } from 'types/app/loading';

export const loadingReducer = (
  state: LoadingState = initialState.app.loading,
  action: LoadingActions,
): LoadingState => {
  switch (action.type) {
    case LoadingActionTypes.CreateReservationPending:
      return {
        ...state,
        is_create_reservation_loading: true,
      };
    case LoadingActionTypes.CreateReservationSuccess:
      return {
        ...state,
        is_create_reservation_loading: false,
      };
    case LoadingActionTypes.CreateReservationRejected:
      return {
        ...state,
        is_create_reservation_loading: false,
      };
    case LoadingActionTypes.UpdateReservationPending:
      return {
        ...state,
        is_update_reservation_loading: true,
      };
    case LoadingActionTypes.UpdateReservationSuccess:
      return {
        ...state,
        is_update_reservation_loading: false,
      };
    case LoadingActionTypes.UpdateReservationRejected:
      return {
        ...state,
        is_update_reservation_loading: false,
      };
    case LoadingActionTypes.DeleteReservationPending:
      return {
        ...state,
        is_delete_reservation_loading: true,
      };
    case LoadingActionTypes.DeleteReservationSuccess:
      return {
        ...state,
        is_delete_reservation_loading: false,
      };
    case LoadingActionTypes.DeleteReservationRejected:
      return {
        ...state,
        is_delete_reservation_loading: false,
      };
    case LoadingActionTypes.SearchReservationsPending:
      return {
        ...state,
        is_search_reservations_loading: true,
      };
    case LoadingActionTypes.SearchReservationsSuccess:
      return {
        ...state,
        is_search_reservations_loading: false,
      };
    case LoadingActionTypes.SearchReservationsRejected:
      return {
        ...state,
        is_search_reservations_loading: false,
      };
    case LoadingActionTypes.SearchReservationsLoadMorePending:
      return {
        ...state,
        is_search_reservations_load_more_loading: true,
      };
    case LoadingActionTypes.SearchReservationsLoadMoreSuccess:
      return {
        ...state,
        is_search_reservations_load_more_loading: false,
      };
    case LoadingActionTypes.SearchReservationsLoadMoreRejected:
      return {
        ...state,
        is_search_reservations_load_more_loading: false,
      };
    case LoadingActionTypes.GetReservationsDetailsPending:
      return {
        ...state,
        is_get_reservations_details_loading: true,
      };
    case LoadingActionTypes.GetReservationsDetailsSuccess:
      return {
        ...state,
        is_get_reservations_details_loading: false,
      };
    case LoadingActionTypes.GetReservationsDetailsRejected:
      return {
        ...state,
        is_get_reservations_details_loading: false,
      };
    default:
      return state;
  }
};
