import { TableManagementState } from 'types/app/tableManagement';

export const layoutInitialState = {
  id: 0,
  name: '',
  online_booking: false,
  archive: false,
  weight: 0,
  rooms: [],
};

export const tableManagementInitialState: TableManagementState = {
  layouts: [],
  groups: [],
};
