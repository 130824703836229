import { initialState } from 'redux/reducers/index';
import {
  INotificationSettingsData,
  NotificationSettingsAction,
  NotificationSettingsActionTypes,
} from 'types/app/notificationSettings';

export const notificationSettingsReducer = (
  state: INotificationSettingsData = initialState.app.settings.notification_settings,
  action: NotificationSettingsAction,
): any => {
  switch (action.type) {
    case NotificationSettingsActionTypes.Loaded:
      return {
        ...state,
        sms: action.payload,
      };
    default:
      return state;
  }
};
