import { AppDispatch } from 'redux/store';
import { getRoomsCall } from 'api/app/restaurant/roomAndTable';
import { RoomsActionTypes } from 'types/app/rooms';

const roomsActions = {
  getRooms() {
    return async (dispatch: AppDispatch) => {
      const response = await getRoomsCall();
      dispatch({
        type: RoomsActionTypes.UpdateRooms,
        payload: response,
      });
      return response;
    };
  },
};

export default roomsActions;
