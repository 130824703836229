import { initialState } from 'redux/reducers/index';
import { BookingAction, BookingFormData } from 'types/app/booking';
import { BookingActionTypes } from 'types/app/booking';
import { unionBy, reject } from 'lodash';

export const bookingReducer = (
  state: BookingFormData = initialState.app.booking,
  action: BookingAction,
): BookingFormData => {
  switch (action.type) {
    case BookingActionTypes.UpdateBooking:
      return {
        ...action.payload,
      };
    case BookingActionTypes.ResetBooking:
      return {
        ...action.payload,
      };
    case BookingActionTypes.SetReservationComment:
      const updatedComments = unionBy([action.payload], state.comments || [], 'comment_id');

      return {
        ...state,
        comments: updatedComments,
      };
    case BookingActionTypes.DeleteReservationComment:
      const commentId = action.payload;
      const filteredComments = reject(state.comments, { comment_id: commentId });

      return {
        ...state,
        comments: filteredComments,
      };
    case BookingActionTypes.UpdateReservationClient:
      return {
        ...state,
        client: action.payload,
      };
    default:
      return state;
  }
};
