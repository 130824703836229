import { applyMiddleware, compose, createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { rootReducer, initialState } from './reducers';
import { createBrowserHistory } from 'history';
import { AnyAction } from 'redux';
import { merge } from 'lodash';
import { AppState } from 'types';

export const history = createBrowserHistory();

const ReduxExtensionComposeName = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';

const saveToLocalStorage = (state: AppState) => {
  try {
    const serialisedState = JSON.stringify({
      app: state.app.app,
      preferences: state.app.preferences,
      config: state.app.config,
      rooms: state.app.rooms,
      tables: state.app.tables,
      reservations: state.app.reservations,
      settings: state.app.settings,
    });
    localStorage.setItem('tbl', serialisedState);
  } catch (e) {
    console.warn(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serialisedState = localStorage.getItem('tbl');
    if (serialisedState === null) return {};

    return JSON.parse(serialisedState);
  } catch (e) {
    return {};
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem('tbl');
};

const composedEnhancers =
  process.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  //@ts-ignore
  window[ReduxExtensionComposeName]
    ? //@ts-ignore
      window[ReduxExtensionComposeName]
    : compose;

const enhancer = composedEnhancers(applyMiddleware(thunk));

const store = createStore(
  rootReducer(),
  merge({}, initialState, { app: loadFromLocalStorage() }),
  enhancer,
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;

export default store;
