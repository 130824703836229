import { AvailableLanguages } from 'enums/availableLanguages';
import { DaysOfTheWeek } from 'enums/daysOfTheWeek';
import { ITimeAndLanguageData } from 'types/app/timeAndLanguage';
import { DateFormatValues } from 'enums/dates';

export const timeAndLanguageInitialState: ITimeAndLanguageData = {
  system_language: '',
  widget_default_language: AvailableLanguages.EN,
  time_zone: 'Europe/Berlin',
  date_format: DateFormatValues.YMD,
  time_zone_offset: 60,
  first_day_of_week: DaysOfTheWeek.Monday,
  widget_available_languages: [],
};
