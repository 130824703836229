import { OpeningHoursActionTypes } from 'types/app/openingHours';
import { getOpeningHoursCall } from 'api/app/restaurant/settings/opening_hours';
import { AppDispatch } from 'redux/store';

const openingHoursActions = {
  getOpeningHours() {
    return async (dispatch: AppDispatch) => {
      try {
        const response = await getOpeningHoursCall();
        dispatch({
          type: OpeningHoursActionTypes.Loaded,
          payload: response,
        });

        return response;
      } catch (error) {
        throw error;
      }
    };
  },
};

export default openingHoursActions;
