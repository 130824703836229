import { LoadingState } from 'types/app/loading';

export const loadingInitialState: LoadingState = {
  is_create_reservation_loading: false,
  is_update_reservation_loading: false,
  is_delete_reservation_loading: false,
  is_search_reservations_loading: false,
  is_search_reservations_load_more_loading: false,
  is_get_reservations_details_loading: false,
};
