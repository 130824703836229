import { initialState } from 'redux/reducers/index';
import { ConfigState, ConfigAction, ConfigActionTypes } from 'types/app/config';

export function configReducer(
  state: ConfigState = initialState.app.config,
  action: ConfigAction,
): ConfigState {
  switch (action.type) {
    case ConfigActionTypes.Loaded:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
