import { initialState } from 'redux/reducers/index';
import { RoomsAction, RoomsActionTypes, RoomsState } from 'types/app/rooms';

export const roomsReducer = (
  state: RoomsState = initialState.app.rooms,
  action: RoomsAction,
): RoomsState => {
  switch (action.type) {
    case RoomsActionTypes.UpdateRooms:
      return action.payload.filter((room) => room.status);
    default:
      return state;
  }
};
