import { initialState } from 'redux/reducers/index';
import {
  WidgetSettingsAction,
  WidgetSettingsActionTypes,
  WidgetSettingsState,
} from 'types/app/widgetSettings';

export const widgetSettingsReducer = (
  state: WidgetSettingsState = initialState.app.settings.widget_settings,
  action: WidgetSettingsAction,
): any => {
  switch (action.type) {
    case WidgetSettingsActionTypes.Loaded:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
