import { ReservationsActionTypes, IReservation, IComment } from 'types/app/reservations';
import {
  getBaseReservationsCall,
  getFullReservationsCall,
  IGetReservationSendData,
} from 'api/app/restaurant/reservation';
import { AppState } from 'types';
import { AppDispatch } from 'redux/store';
import { LoadingActionTypes } from 'types/app/loading';

const reservationsActions = {
  getBaseReservations({ start_time, end_time }: IGetReservationSendData) {
    return async (dispatch: AppDispatch) => {
      try {
        const response = await getBaseReservationsCall({
          start_time,
          end_time,
        });

        dispatch({
          type: ReservationsActionTypes.UpdateBaseReservations,
          payload: response,
        });

        return response;
      } catch (error) {
        throw error;
      }
    };
  },
  getFullReservationsForDay() {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
      dispatch({ type: LoadingActionTypes.GetReservationsDetailsPending });

      const {
        time: { start, end },
        is_closed,
      } = getState().app.app.opening_hour;

      if (is_closed) {
        return;
      }

      try {
        const detailsResponse: IReservation[] = await getFullReservationsCall({
          start_time: start,
          end_time: end,
        });

        dispatch({
          type: ReservationsActionTypes.UpdateFullReservations,
          payload: {
            time: {
              start,
              end,
            },
            reservations: detailsResponse,
          },
        });
        dispatch({ type: LoadingActionTypes.GetReservationsDetailsSuccess });
      } catch (error) {
        dispatch({ type: LoadingActionTypes.GetReservationsDetailsRejected });
        throw error;
      }
    };
  },
  findReservationAndSetComment(reservationId: number, comment: IComment) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: ReservationsActionTypes.FindReservationAndSetComment,
        payload: {
          reservationId,
          comment,
        },
      });
    };
  },
  findReservationAndDeleteComment(reservationId: number, commentId: number) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: ReservationsActionTypes.FindReservationAndDeleteComment,
        payload: {
          reservationId,
          commentId,
        },
      });
    };
  },
  setReservationLoading(reservationId: number | undefined, loadingStatus: boolean) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: ReservationsActionTypes.SetReservationLoading,
        payload: {
          reservationId,
          loadingStatus,
        },
      });
    };
  },
};

export default reservationsActions;
