import moment from 'moment-timezone';

export const applicationInitialState = {
  languages: [],
  restaurantsList: [],
  printing: false,
  currentTime: moment(),
  connectionStatus: {
    isServerAvailable: true,
    isOffline: false,
  },
};
