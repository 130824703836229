import { Dispatch } from 'redux';
import {
  getLocaleCall,
  changeTimeReservationCall,
  deleteReservationCall,
  getRestaurantCall,
  getTablesCall,
  getWaitersCall,
  saveReservationCall,
  blockReservationHoursCall,
  getReservationsCall,
  searchReservationsCall,
} from 'api/calendar';
import moment from 'moment-timezone';
import { AppState } from 'types';
import {
  prepareReservationFromReservationState,
  prepareReservationsFromApi,
  setSelectedCalendarDay,
} from 'utils/reservation/reservations';
import { calculateOpenHoursInDay } from 'utils/date-time/dates';
import notesActions from './notes';
import { ReservationState, Statuses } from 'types/reservation';
import {
  AdvancedSearchParams,
  CalendarChangeReservationListDateRangeAction,
  CalendarControlsSetSelectedDateIndex,
  CalendarControlsSetStartDateFactor,
  CalendarSettingsAddedAction,
  ExportListToCsvUpdateAction,
  ReservationListStatisticsSetAction,
  FullBookingModalClosedAction,
  IsLoadingFullBookingModalSetAction,
  LateCancellationNoShowPyamentConfirmModalCloseAction,
  LateCancellationNoShowPyamentConfirmModalOpenAction,
  LateCancellationNoShowPyamentModalCloseAction,
  LateCancellationNoShowPyamentModalOpenAction,
  LocaleSetAction,
  ReservationAddedToCalendarAction,
  ReservationAddedToFailedListAction,
  ReservationChangedInCalendarAction,
  ReservationListAdvancedSearchSetLoading,
  ReservationListAdvancedSearchSetNextPageLoading,
  ReservationListAdvancedSearchSetParams,
  ReservationListAdvancedSearchSetResults,
  ReservationListAdvancedSearchUpdatePagination,
  ReservationRemovedFromCalendarAction,
  ReservationRemovedFromFailedListAction,
  ReservationsChangeDateRangeAction,
  ReservationsIsLoadingSetAction,
  ReservationsIsNextPageLoadingSetAction,
  ReservationsLoadedAction,
  ReservationsUpdatePaginationAction,
  RestaurantLoadedAction,
  RestaurantWaitersLoadedAction,
  Room,
  RoomOrTable,
  RoomsLoadedAction,
  SearchToggledAction,
  SidebarToggledAction,
  Statistics,
  TodaySetAction,
  FullBookingModalOpenedAction,
} from 'types/calendar';
import filtersActions from './filters';
import notificationsActions from './notifications';
import { queryAllData, queryRestaurant, queryRoomsAndTables } from 'app/data/dataservice';
import { prepareReservationsFromLocalDatabase } from 'app/data/prepareReservationsFromLocalDatabase';
import { prepareRestaurantFromLocalDatabase } from 'app/data/prepareRestaurantFromLocalDatabase';
import { prepareRoomsAndTablesFromLocalDatabase } from 'app/data/prepareRoomsAndTablesFromLocalDatabase';
import { initialState } from '../reducers';
import systemActions from './system';
import { getToken } from 'utils/auth/tokenManagement';
import { timestamp } from 'utils/date-time/timestamp';
import constants from 'utils/storage/constants';

const calendarActions = {
  setLocale() {
    return (dispatch: Dispatch) => {
      getLocaleCall().then((locale) => {
        dispatch({
          type: 'Calendar.LocaleSet',
          payload: locale,
        } as LocaleSetAction);
      });
    };
  },
  openFullBookingModal() {
    return {
      type: 'Calendar.FullBookingModalOpened',
      payload: { fullBookingModalOpen: true },
    } as FullBookingModalOpenedAction;
  },
  closeFullBookingModal() {
    return {
      type: 'Calendar.FullBookingModalClosed',
      payload: { fullBookingModalOpen: false },
    } as FullBookingModalClosedAction;
  },
  toggleSidebar() {
    return (dispatch: Dispatch) => {
      dispatch({
        type: 'Calendar.SidebarToggled',
      } as SidebarToggledAction);
    };
  },
  toggleSearch() {
    return (dispatch: Dispatch) => {
      dispatch({
        type: 'Calendar.SearchToggled',
      } as SearchToggledAction);
    };
  },
  setStartDateFactor(startDateFactor: number) {
    return {
      type: 'Calendar.ControlsStartDateFactorSet',
      payload: { startDateFactor },
    } as CalendarControlsSetStartDateFactor;
  },
  setSelectedDateIndex(selectedDateIndex: number) {
    return {
      type: 'Calendar.ControlsSelectedDateIndexSet',
      payload: { selectedDateIndex },
    } as CalendarControlsSetSelectedDateIndex;
  },
  changeReservationsDateRange(
    startDate: moment.Moment,
    endDate: moment.Moment,
    _filters: any,
    _isOffline?: any,
  ) {
    return async (dispatch: Dispatch) => {
      dispatch<any>(
        calendarActions.getReservations(
          startDate.format('YYYY-MM-DD'),
          endDate.format('YYYY-MM-DD'),
          {},
          _isOffline,
        ),
      );
      return dispatch({
        type: 'Calendar.ReservationsChangeDateRange',
        payload: { startDate, endDate },
      } as ReservationsChangeDateRangeAction);
    };
  },
  changeReservationsPage(page: number) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const reservationSettings = getState().calendar.reservationsSettings;
      if (reservationSettings.page === page) return;
      dispatch<any>(
        calendarActions.getReservations(
          reservationSettings.startDate.format('YYYY-MM-DD'),
          reservationSettings.endDate.format('YYYY-MM-DD'),
          { page },
        ),
      );
    };
  },
  changeReservationListDateRange(
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    _filters?: any,
    _isOffline?: any,
  ) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const store = getState().calendar;

      // change date range and fetch more reservations if necessary
      if (!!startDate && !!endDate) {
        dispatch<any>(
          calendarActions.changeReservationsDateRange(startDate, endDate, {}, _isOffline),
        );
      } else if (!startDate && !endDate) {
        dispatch<any>(
          calendarActions.changeReservationsDateRange(
            store.calendarSettings.date,
            store.calendarSettings.date,
            {},
            _isOffline,
          ),
        );
      }

      return dispatch({
        type: 'Calendar.ChangeReservationListDateRange',
        payload: { startDate, endDate },
      } as CalendarChangeReservationListDateRangeAction);
    };
  },
  changeCalendarDate(val: any, shouldMoveSlider = true, _isOffline?: boolean | null) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const store = getState().calendar;
      const selectedFilterHours = getState().filters.selectedHours;
      let date: moment.Moment;

      if (val === 'today') {
        date = moment();
      } else if (
        (typeof val === 'object' && val instanceof Date) ||
        (typeof val === 'object' && val instanceof moment)
      ) {
        date = moment(val);
      } else {
        date = moment(store.calendarSettings.date).add(val, 'days');
      }
      setSelectedCalendarDay(date, store.restaurant.nid);

      dispatch<any>(calendarActions.changeReservationsDateRange(date, date, {}, _isOffline));

      const { data, start, end } = calculateOpenHoursInDay(date, store);

      if (val === 'today') {
        const dateYesterday = moment().add(-1, 'day');
        const {
          data: dataYesterday,
          start: startYesterday,
          end: endYesterday,
        } = calculateOpenHoursInDay(dateYesterday, store);

        // If restaurant is open from yesterday (after midnight)
        if (!dataYesterday.closed && date.isBefore(endYesterday)) {
          dispatch({
            type: 'Calendar.TodaySet',
            payload: {
              today: dateYesterday,
            },
          } as TodaySetAction);
          return dispatch({
            type: 'Calendar.SettingsAdded',
            payload: {
              closed: !!dataYesterday.closed,
              startTime: startYesterday,
              endTime: endYesterday,
              date: dateYesterday,
            },
          } as CalendarSettingsAddedAction);
        }
      }

      if (shouldMoveSlider) {
        dispatch<any>(calendarActions.changeDateSlider(date));
      }

      if (!!selectedFilterHours.from || !!selectedFilterHours.from) {
        dispatch<any>(filtersActions.resetFilters());
      }

      dispatch<any>(notesActions.getNotes(date.format('YYYY-MM-DD'), _isOffline));

      if (!_isOffline) {
        dispatch<any>(systemActions.getStatus());
      }

      await dispatch({
        type: 'Calendar.SettingsAdded',
        payload: {
          closed: !!data.closed,
          startTime: start,
          endTime: end,
          date: date,
        },
      } as CalendarSettingsAddedAction);
    };
  },
  changeDateSlider(val: any, changeFactor = 0, move = true) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const calendarStore = getState().calendar;
      const { startDateFactor, selectedDateIndex } =
        calendarStore.calendarSettings.controlsSettings;
      const showDaysLength = constants.VARIABLES.showDaysLength;

      if (val === 'today') {
        dispatch<any>(calendarActions.setSelectedDateIndex(0));
        dispatch<any>(calendarActions.setStartDateFactor(0));
      } else if (!move) {
        const diff = moment(val)
          .startOf('day')
          .diff(moment(calendarStore.today).startOf('day'), 'days');
        dispatch<any>(calendarActions.setSelectedDateIndex(diff - startDateFactor));
      } else if (!changeFactor) {
        const diff = moment(val)
          .startOf('day')
          .diff(moment(calendarStore.today).startOf('day'), 'days');
        dispatch<any>(calendarActions.setSelectedDateIndex(0));
        dispatch<any>(calendarActions.setStartDateFactor(diff || 0));
      } else {
        let newSelectedDateIndex = selectedDateIndex + changeFactor;
        let newStartDateFactor = startDateFactor;

        if (newSelectedDateIndex >= showDaysLength) {
          newSelectedDateIndex -= changeFactor;
          newStartDateFactor++;
        }

        if (newSelectedDateIndex < 0) {
          newSelectedDateIndex = 0;
          newStartDateFactor--;
        }

        dispatch<any>(calendarActions.setSelectedDateIndex(newSelectedDateIndex));
        dispatch<any>(calendarActions.setStartDateFactor(newStartDateFactor));
      }
    };
  },
  //TODO remove in future
  getTables(_isOffline?: boolean | null) {
    return async (dispatch: Dispatch) => {
      let groups: RoomOrTable[] = [];
      let rooms = [];

      if (_isOffline) {
        const roomsAndTablesData = await queryRoomsAndTables();
        const preparedRoomsAndTables = prepareRoomsAndTablesFromLocalDatabase(roomsAndTablesData);
        groups = preparedRoomsAndTables.groups;
        rooms = preparedRoomsAndTables.rooms;
      } else {
        const roomsAndTables = await getTablesCall();
        rooms = roomsAndTables;
        roomsAndTables.forEach((room: Room) => {
          groups.push({
            id: `${room.nid}`,
            roomId: room.nid,
            title: room.title,
            root: true,
            collapsed: room.collapsed,
          });
          room.tables.forEach((table) => {
            groups.push({
              id: `${table.nid}`,
              tableId: table.nid,
              title: table.title,
              root: false,
              parent: room.nid,
              covers_min: table.covers_min,
              covers_max: table.covers_max,
            });
          });
        });
      }

      dispatch({
        type: 'Calendar.RoomsLoaded',
        payload: { roomsAndTables: groups, rooms: rooms },
      } as RoomsLoadedAction);
    };
  },
  getRestaurant(_isOffline?: any) {
    return async (dispatch: Dispatch, getSate: () => AppState) => {
      const currentDate = getSate().app.app.current_date;
      let restaurant = {
        restaurant: initialState.calendar.restaurant,
      };

      if (_isOffline) {
        const restaurantData = await queryRestaurant();
        restaurant = prepareRestaurantFromLocalDatabase(restaurantData);
      } else {
        restaurant = await getRestaurantCall();
      }

      dispatch({
        type: 'Calendar.TodaySet',
        payload: {
          today: moment(),
        },
      } as TodaySetAction);

      dispatch({
        type: 'Calendar.RestaurantLoaded',
        payload: { restaurant: restaurant.restaurant },
      } as RestaurantLoadedAction);

      const oldCurrentDate = moment(timestamp(currentDate).toMilliseconds);
      dispatch<any>(calendarActions.changeCalendarDate(oldCurrentDate, false));
      dispatch<any>(calendarActions.getReservations(undefined, undefined, {}, _isOffline));
    };
  },
  getRestaurantWaiters() {
    return (dispatch: Dispatch) => {
      getWaitersCall().then((waiters) => {
        dispatch({
          type: 'Calendar.RestaurantWaitersLoaded',
          payload: { waiters: waiters },
        } as RestaurantWaitersLoadedAction);
      });
    };
  },
  //TODO remove in future
  getReservations(_startDate?: string, _endDate?: string, _filters?: any, _isOffline?: any) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const restaurantSettings = getState().calendar.restaurant.settings;
      const startDate =
        _startDate || getState().calendar.reservationsSettings.startDate.format('YYYY-MM-DD');
      const endDate =
        _endDate || getState().calendar.reservationsSettings.endDate.format('YYYY-MM-DD');

      const filters = _filters || {};

      //Enable pagination for multi-day date range
      if (!moment(startDate).isSame(endDate, 'day')) {
        filters.order_by = 'start_time';
        filters.order = 'ASC';

        if (!filters.hasOwnProperty('page')) {
          filters.page = 1;
        }

        if (!filters.hasOwnProperty('limit')) {
          filters.limit = constants.VARIABLES['paginationLimit'];
        }

        // Add filters for restaurant settings
        if (!restaurantSettings.list_show_walk_ins) {
          filters['hide_walk_in'] = true;
        }

        if (!restaurantSettings.list_show_cancelled) {
          filters.status = 'active';
        }
      } else {
        filters.track_changes_audit_field = 'field_time_intervals';
      }

      if (filters.page > 1) {
        dispatch({
          type: 'Calendar.ReservationsIsNextPageLoadingSet',
          payload: { isLoading: true },
        } as ReservationsIsNextPageLoadingSetAction);
      } else {
        dispatch({
          type: 'Calendar.ReservationsIsLoadingSet',
          payload: { isLoading: true },
        } as ReservationsIsLoadingSetAction);
      }

      try {
        const store = getState().calendar;
        const allTables = store.roomsAndTables.filter(
          (roomOrTable) => roomOrTable.tableId !== undefined,
        );
        let data: any = [];
        let reservations: any = [];

        if (_isOffline) {
          const allData = await queryAllData();
          data = prepareReservationsFromLocalDatabase(allData);
          data = prepareReservationsFromLocalDatabase(allData).reservationsData.filter((i: any) => {
            return moment(i.reservation.startTime).format('YYYY-MM-DD') === startDate;
          });
        } else {
          const reservationsCall = await getReservationsCall(startDate, endDate, filters);
          reservations = reservationsCall;
          data = prepareReservationsFromApi(reservationsCall.data, restaurantSettings, allTables);
        }

        dispatch({
          type: 'Calendar.ReservationsLoaded',
          payload: {
            reservations: reservations['current_page'] > 1 ? store.reservations.concat(data) : data,
          },
        } as ReservationsLoadedAction);

        if (reservations['current_page'] > 1) {
          dispatch({
            type: 'Calendar.ReservationsIsNextPageLoadingSet',
            payload: { isLoading: false },
          } as ReservationsIsNextPageLoadingSetAction);
        } else {
          dispatch({
            type: 'Calendar.ReservationsIsLoadingSet',
            payload: { isLoading: false },
          } as ReservationsIsLoadingSetAction);
        }

        dispatch({
          type: 'Calendar.ReservationsUpdatePagination',
          payload: {
            page: reservations['current_page'],
            pagesCount: reservations['page_count'],
          },
        } as ReservationsUpdatePaginationAction);
        return reservations;
      } catch (err: any) {
        const token = getToken();
        if (!err['__CANCEL__'] && token) {
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Could not get reservations. Please try again later.',
              options: { variant: 'error' },
            }),
          );
        }
        dispatch({
          type: 'Calendar.ReservationsIsNextPageLoadingSet',
          payload: { isLoading: false },
        } as ReservationsIsNextPageLoadingSetAction);
        dispatch({
          type: 'Calendar.ReservationsIsLoadingSet',
          payload: { isLoading: false },
        } as ReservationsIsLoadingSetAction);
      }
    };
  },
  searchReservations(_searchParams?: AdvancedSearchParams, page?: number) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const searchParams =
        _searchParams || getState().calendar.reservationListSettings.advancedSearch.params;

      if (!searchParams) return;

      const params = {
        page: page || 1,
        limit: constants.VARIABLES['paginationLimit'],
        order_by: 'start_time',
        order: 'ASC',
        ...searchParams,
      };

      if (params.page !== 1) {
        dispatch({
          type: 'Calendar.ReservationListAdvancedSearchSetNextPageLoading',
          payload: { isLoading: true },
        } as ReservationListAdvancedSearchSetNextPageLoading);
      } else {
        dispatch({
          type: 'Calendar.ReservationListAdvancedSearchSetLoading',
          payload: { isLoading: true },
        } as ReservationListAdvancedSearchSetLoading);
      }

      const restaurantSettings = getState().calendar.restaurant.settings;

      searchReservationsCall(params).then((reservations) => {
        const allTables = getState().calendar.roomsAndTables.filter(
          (roomOrTable) => roomOrTable.tableId !== undefined,
        );
        const prevResults = getState().calendar.reservationListSettings.advancedSearch.results;
        const results = prepareReservationsFromApi(
          reservations.data,
          restaurantSettings,
          allTables,
        );

        dispatch({
          type: 'Calendar.ReservationListAdvancedSearchSetParams',
          payload: { params: searchParams },
        } as ReservationListAdvancedSearchSetParams);

        dispatch({
          type: 'Calendar.ReservationListAdvancedSearchSetResults',
          payload: {
            results: reservations['current_page'] !== 1 ? prevResults.concat(results) : results,
          },
        } as ReservationListAdvancedSearchSetResults);

        dispatch({
          type: 'Calendar.ReservationListAdvancedSearchUpdatePagination',
          payload: {
            page: reservations['current_page'],
            pagesCount: reservations['page_count'],
          },
        } as ReservationListAdvancedSearchUpdatePagination);

        dispatch({
          type: 'Calendar.ReservationListAdvancedSearchSetLoading',
          payload: { isLoading: false },
        } as ReservationListAdvancedSearchSetLoading);

        dispatch({
          type: 'Calendar.ReservationListAdvancedSearchSetNextPageLoading',
          payload: { isLoading: false },
        } as ReservationListAdvancedSearchSetNextPageLoading);
      });
    };
  },
  resetSearchReservation() {
    return (dispatch: Dispatch) => {
      dispatch({
        type: 'Calendar.ReservationListAdvancedSearchSetResults',
        payload: { results: [] },
      } as ReservationListAdvancedSearchSetResults);

      dispatch({
        type: 'Calendar.ReservationListAdvancedSearchSetParams',
        payload: { params: null },
      } as ReservationListAdvancedSearchSetParams);

      dispatch({
        type: 'Calendar.ReservationListAdvancedSearchUpdatePagination',
        payload: { page: 1, pagesCount: 1 },
      } as ReservationListAdvancedSearchUpdatePagination);
    };
  },
  saveReservation() {
    return (dispatch: Dispatch, getState: () => AppState) => {
      const state = getState();
      const newReservation = state.reservation;
      const restaurantSettings = state.calendar.restaurant.settings;
      const newTimelineReservation = prepareReservationFromReservationState(
        newReservation,
        restaurantSettings,
        true,
      );
      dispatch({
        type: 'Calendar.FullBookingModalClosed',
      } as FullBookingModalClosedAction);
      if (!newReservation.id) {
        dispatch({
          type: 'Calendar.ReservationAddedToCalendar',
          payload: { reservation: newTimelineReservation },
        } as ReservationAddedToCalendarAction);
      } else {
        dispatch({
          type: 'Calendar.ReservationChangedInCalendar',
          payload: { reservation: newTimelineReservation },
        } as ReservationChangedInCalendarAction);
      }
      saveReservationCall(newReservation, state.calendar.restaurant.settings.date_format_key)
        .then((response) => {
          dispatch({
            type: 'Calendar.IsLoadingFullBookingModalSet',
            payload: { isLoading: false },
          } as IsLoadingFullBookingModalSetAction);
          if (response.errors) {
            if (!newTimelineReservation.reservation.id) {
              dispatch({
                type: 'Calendar.ReservationRemovedFromCalendar',
                payload: { reservationId: newTimelineReservation.id },
              } as ReservationRemovedFromCalendarAction);
            } else if (
              [Statuses.Cancelled, Statuses.NoShow].includes(
                newTimelineReservation.reservation.status,
              )
            ) {
              dispatch({
                type: 'Calendar.ReservationAddedToCalendar',
                payload: {
                  reservation: {
                    ...newTimelineReservation,
                    reservation: {
                      ...newTimelineReservation.reservation,
                      status: Statuses.Confirmed,
                    },
                  },
                },
              } as ReservationAddedToCalendarAction);
            }
            dispatch({
              type: 'Calendar.ReservationAddedToFailedList',
              payload: {
                reservation: newTimelineReservation,
                errors: Object.keys(response.errors).map((key) => {
                  return {
                    field: key,
                    error: response.errors[key],
                  };
                }),
              },
            } as ReservationAddedToFailedListAction);
          } else {
            dispatch<any>(calendarActions.searchReservations());
            dispatch(
              notificationsActions.enqueueSnackbar({
                message: response,
                options: { variant: 'success' },
              }),
            );
          }
        })
        .catch(() => {
          const newTimelineReservation = prepareReservationFromReservationState(
            newReservation,
            state.calendar.restaurant.settings,
            false,
          );
          dispatch({
            type: 'Calendar.ReservationAddedToCalendar',
            payload: { reservation: newTimelineReservation },
          } as ReservationAddedToCalendarAction);
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Reservation not saved: ' + newTimelineReservation.reservation.id,
              options: { variant: 'error' },
            }),
          );
        })
        .finally(() => {
          dispatch<any>(calendarActions.getReservations());
        });
    };
  },
  changeTimeReservation(reservationId: string, tableIds: string[], from: string, till: string) {
    return (dispatch: Dispatch) => {
      changeTimeReservationCall(reservationId, tableIds, from, till).then(() => {
        dispatch<any>(calendarActions.getReservations());
      });
    };
  },
  deleteReservation(reservation: ReservationState) {
    return (dispatch: Dispatch, getState: () => AppState) => {
      if (!reservation.id) return;
      const calendar = getState().calendar;
      dispatch({
        type: 'Calendar.ReservationRemovedFromCalendar',
        payload: { reservationId: reservation.id },
      } as ReservationRemovedFromCalendarAction);
      deleteReservationCall(reservation.id)
        .catch(() => {
          const newTimelineReservation = prepareReservationFromReservationState(
            reservation,
            calendar.restaurant.settings,
            false,
          );
          dispatch({
            type: 'Calendar.ReservationAddedToCalendar',
            payload: { reservation: newTimelineReservation },
          } as ReservationAddedToCalendarAction);
          dispatch(
            notificationsActions.enqueueSnackbar({
              message: 'Reservation not deleted: ' + reservation.id,
              options: { variant: 'error' },
            }),
          );
        })
        .finally(() => {
          dispatch<any>(calendarActions.searchReservations());
          dispatch<any>(calendarActions.getReservations());
        });
    };
  },
  removeReservationFromFailedList(timelineReservationId: number) {
    return {
      type: 'Calendar.ReservationRemovedFromFailedList',
      payload: { timelineReservationId: timelineReservationId },
    } as ReservationRemovedFromFailedListAction;
  },
  setReservationListStatistics(statistics: Statistics) {
    return {
      type: 'Calendar.ReservationListStatisticsSet',
      payload: { statistics },
    } as ReservationListStatisticsSetAction;
  },
  //TODO uncomment BlockReservationHours
  blockReservationHours(blockedHoursData: any) {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      try {
        await blockReservationHoursCall(blockedHoursData);
        const state = getState();
        const newRestaurant = { ...state.calendar.restaurant };
        newRestaurant.cumoWidgetSpecificTimes.times = {
          ...newRestaurant.cumoWidgetSpecificTimes.times,
          [blockedHoursData.currentDate]: blockedHoursData.hours,
        };

        dispatch({
          type: 'Calendar.RestaurantLoaded',
          payload: { restaurant: newRestaurant },
        });

        dispatch(
          notificationsActions.enqueueSnackbar({
            message: 'Blocked hours updated.',
            options: { variant: 'success' },
          }),
        );
      } catch (error) {
        dispatch(
          notificationsActions.enqueueSnackbar({
            message: 'An error occurred. Please try again later.',
            options: { variant: 'error' },
          }),
        );
      }
    };
  },
  updateExportListToCsv(exportListToCsv: boolean) {
    return {
      type: 'Calendar.ExportListToCsvUpdateAction',
      payload: { exportListToCsv: exportListToCsv },
    } as ExportListToCsvUpdateAction;
  },
  openLateCancellationNoShowPaymentConfirmationModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentConfirmationModalOpen',
      payload: { askForPaymentModalIsOpen: true },
    } as LateCancellationNoShowPyamentConfirmModalOpenAction;
  },
  closeLateCancellationNoShowPaymentConfirmationModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentConfirmationModalClose',
      payload: { askForPaymentModalIsOpen: false },
    } as LateCancellationNoShowPyamentConfirmModalCloseAction;
  },
  openLateCancellationNoShowPaymentModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentModalOpen',
      payload: { paymentModalIsOpen: true },
    } as LateCancellationNoShowPyamentModalOpenAction;
  },
  closeLateCancellationNoShowPaymentModal() {
    return {
      type: 'Calendar.LateCancellationNoShow.PaymentModalClose',
      payload: { paymentModalIsOpen: false },
    } as LateCancellationNoShowPyamentModalCloseAction;
  },
};

export default calendarActions;
