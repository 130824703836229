import { initialState } from './index';
import moment from 'moment';
import { CalendarAction, CalendarState } from 'types/calendar';

export function calendarReducer(
  state: CalendarState = initialState.calendar,
  action: CalendarAction,
): CalendarState {
  switch (action.type) {
    case 'Calendar.LocaleSet':
      return {
        ...state,
        locale: action.payload.locale,
      };
    case 'Calendar.FullBookingModalOpened':
      return {
        ...state,
        fullBookingModalOpen: true,
      };
    case 'Calendar.FullBookingModalClosed':
      return {
        ...state,
        fullBookingModalOpen: false,
      };
    case 'Calendar.IsLoadingFullBookingModalSet':
      return {
        ...state,
        fullBookingModalIsLoading: action.payload.isLoading,
      };
    case 'Calendar.ReservationsIsLoadingSet':
      return {
        ...state,
        reservationsIsLoading: action.payload.isLoading,
      };
    case 'Calendar.ReservationsIsNextPageLoadingSet':
      return {
        ...state,
        reservationsIsNextPageLoading: action.payload.isLoading,
      };
    case 'Calendar.ExtendedReservationsIsLoadingSet':
      return {
        ...state,
        extendedReservationsIsLoading: action.payload.isLoading,
      };
    case 'Calendar.SidebarToggled':
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    case 'Calendar.SearchToggled':
      return {
        ...state,
        searchOpen: !state.searchOpen,
      };
    case 'Calendar.TodaySet':
      return {
        ...state,
        today: action.payload.today,
      };
    case 'Calendar.CalendarDisplayChanged':
      return {
        ...state,
        calendarDisplay: action.payload.calendarDisplay,
      };
    case 'Calendar.ControlsSelectedDateIndexSet':
      return {
        ...state,
        calendarSettings: {
          ...state.calendarSettings,
          controlsSettings: {
            ...state.calendarSettings.controlsSettings,
            selectedDateIndex: action.payload.selectedDateIndex,
          },
        },
      };
    case 'Calendar.ControlsStartDateFactorSet':
      return {
        ...state,
        calendarSettings: {
          ...state.calendarSettings,
          controlsSettings: {
            ...state.calendarSettings.controlsSettings,
            startDateFactor: action.payload.startDateFactor,
          },
        },
      };

    case 'Calendar.RoomsLoaded':
      return {
        ...state,
        roomsAndTables: action.payload.roomsAndTables,
        rooms: action.payload.rooms,
      };
    case 'Calendar.ReservationsPartialLoaded':
      const d = moment(action.payload.date);
      const d1 = moment(d).add(1, 'day');
      return {
        ...state,
        reservations: [
          ...state.reservations.filter((reservation) => {
            return (
              !moment(reservation.start).isSame(d, 'day') &&
              !moment(reservation.start).isSame(d1, 'day')
            );
          }),
          ...action.payload.reservations,
        ],
      };
    case 'Calendar.ReservationsLoaded':
      return {
        ...state,
        reservations: action.payload.reservations,
      };
    case 'Calendar.RestaurantLoaded':
      return {
        ...state,
        restaurant: { ...state.restaurant, ...action.payload.restaurant },
      };
    case 'Calendar.RestaurantWaitersLoaded':
      return {
        ...state,
        restaurant: { ...state.restaurant, waiters: action.payload.waiters },
      };
    case 'Calendar.ReservationAddedToCalendar':
      return {
        ...state,
        reservations: [...state.reservations, action.payload.reservation],
      };
    case 'Calendar.ReservationChangedInCalendar':
      return {
        ...state,
        reservations: state.reservations.map((res) => {
          return res.reservation.id === action.payload.reservation.reservation.id
            ? action.payload.reservation
            : res;
        }),
      };
    case 'Calendar.ReservationRemovedFromCalendar':
      return {
        ...state,
        reservations: state.reservations.filter((el) => el.id !== action.payload.reservationId),
      };
    case 'Calendar.ReservationAddedToFailedList':
      return {
        ...state,
        failedReservationsList: [
          ...state.failedReservationsList,
          {
            ...action.payload.reservation,
            calendarDate: moment(state.calendarSettings.date),
            reservation: {
              ...action.payload.reservation.reservation,
              reservationSavingErrors: action.payload.errors,
            },
          },
        ],
      };
    case 'Calendar.SettingsAdded':
      return {
        ...state,
        calendarSettings: {
          ...state.calendarSettings,
          endTime: action.payload.endTime,
          startTime: action.payload.startTime,
          closed: action.payload.closed,
          date: action.payload.date,
        },
      };
    case 'Calendar.ReservationListStatisticsSet':
      return {
        ...state,
        reservationListStatistics: action.payload.statistics,
      };
    case 'Calendar.ReservationRemovedFromFailedList':
      return {
        ...state,
        failedReservationsList: state.failedReservationsList.filter((reservation) => {
          return reservation.id !== action.payload.timelineReservationId;
        }),
      };
    case 'Calendar.ExportListToCsvUpdateAction':
      return {
        ...state,
        exportListToCsv: action.payload.exportListToCsv,
      };
    case 'Calendar.LateCancellationNoShow.PaymentConfirmationModalClose':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          askForPaymentModalIsOpen: action.payload.askForPaymentModalIsOpen,
        },
      };
    case 'Calendar.LateCancellationNoShow.PaymentConfirmationModalOpen':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          askForPaymentModalIsOpen: action.payload.askForPaymentModalIsOpen,
        },
      };
    case 'Calendar.LateCancellationNoShow.PaymentModalOpen':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          paymentModalIsOpen: action.payload.paymentModalIsOpen,
        },
      };
    case 'Calendar.LateCancellationNoShow.PaymentModalClose':
      return {
        ...state,
        lateCancellationNoShowPayment: {
          ...state.lateCancellationNoShowPayment,
          paymentModalIsOpen: action.payload.paymentModalIsOpen,
        },
      };
    case 'Calendar.ChangeReservationListDateRange':
      return {
        ...state,
        reservationListSettings: {
          ...state.reservationListSettings,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'Calendar.ReservationListAdvancedSearchSetParams':
      return {
        ...state,
        reservationListSettings: {
          ...state.reservationListSettings,
          advancedSearch: {
            ...state.reservationListSettings.advancedSearch,
            params: action.payload.params,
          },
        },
      };
    case 'Calendar.ReservationListAdvancedSearchSetResults':
      return {
        ...state,
        reservationListSettings: {
          ...state.reservationListSettings,
          advancedSearch: {
            ...state.reservationListSettings.advancedSearch,
            results: action.payload.results,
          },
        },
      };
    case 'Calendar.ReservationListAdvancedSearchSetLoading':
      return {
        ...state,
        reservationListSettings: {
          ...state.reservationListSettings,
          advancedSearch: {
            ...state.reservationListSettings.advancedSearch,
            isLoading: action.payload.isLoading,
          },
        },
      };
    case 'Calendar.ReservationListAdvancedSearchSetNextPageLoading':
      return {
        ...state,
        reservationListSettings: {
          ...state.reservationListSettings,
          advancedSearch: {
            ...state.reservationListSettings.advancedSearch,
            isNextPageLoading: action.payload.isLoading,
          },
        },
      };
    case 'Calendar.ReservationListAdvancedSearchUpdatePagination':
      return {
        ...state,
        reservationListSettings: {
          ...state.reservationListSettings,
          advancedSearch: {
            ...state.reservationListSettings.advancedSearch,
            page: action.payload.page,
            pagesCount: action.payload.pagesCount,
          },
        },
      };
    case 'Calendar.ReservationsChangeDateRange':
      return {
        ...state,
        reservationsSettings: {
          ...state.reservationsSettings,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'Calendar.ReservationsUpdatePagination':
      return {
        ...state,
        reservationsSettings: {
          ...state.reservationsSettings,
          page: action.payload.page,
          pagesCount: action.payload.pagesCount,
        },
      };
    default:
      return state;
  }
}
