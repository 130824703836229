import {
  AppActionTypes,
  IFilters,
  ISearchReservations,
  ISearchTime,
  UpdateSearchFieldPayload,
  ISearchReservationsData,
} from 'types/app/app';
import { AppState } from 'types';
import { AppDispatch } from 'redux/store';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import reservationsActions from 'redux/actions/app/reservations';
import openingHoursActions from 'redux/actions/app/openingHours';
import {
  filtersInitialState,
  searchedReservationsInitialState,
  searchReservationsInitialState,
} from 'redux/constants/app/appInitialState';
import { searchReservationsCall } from 'api/app/restaurant/reservation/search';

import { LoadingActionTypes } from 'types/app/loading';
import { prepareSearchReservationsData } from 'utils/data-processors/prepareSearchReservationsData';

const appActions = {
  changeCurrentDate(newDate: number) {
    return async (dispatch: AppDispatch) => {
      dispatch({
        type: AppActionTypes.ChangeCurrentDate,
        payload: {
          current_date: newDate,
        },
      });

      dispatch(appActions.updateOpeningHour());
      await dispatch(openingHoursActions.getOpeningHours());
      await dispatch(reservationsActions.getFullReservationsForDay());
    };
  },
  updateDisplayedDates(dates: number[]) {
    return {
      type: AppActionTypes.UpdateDisplayedDates,
      payload: {
        displayed_dates: dates,
      },
    };
  },
  updateOpeningHour() {
    return (dispatch: AppDispatch, getState: () => AppState) => {
      const state = getState();
      const openingHours = state.app.settings.opening_hours;
      const currentDate = state.app.app.current_date;

      const dayOfWeek = createMomentUtc(currentDate).day();

      let matchedOpeningHour = openingHours.find((hour) => {
        if (hour?.date?.start && hour?.date?.end) {
          return currentDate >= hour?.date?.start && currentDate <= hour?.date?.end;
        }
        return false;
      });

      if (!matchedOpeningHour) {
        matchedOpeningHour = openingHours.find((hour) => hour.day_of_week === dayOfWeek);
      }

      if (matchedOpeningHour) {
        const updatedBlockedTimes = matchedOpeningHour.blocked_times.map((room) => ({
          ...room,
          times: room.times.map((time) => time + currentDate),
        }));

        const opening_hour = {
          ...matchedOpeningHour,
          time: {
            start: matchedOpeningHour?.time.start + currentDate,
            end: matchedOpeningHour?.time.end + currentDate,
            rounded_start: matchedOpeningHour?.time.rounded_start + currentDate,
            rounded_end: matchedOpeningHour?.time.rounded_end + currentDate,
          },
          blocked_times: updatedBlockedTimes,
        };

        dispatch({
          type: AppActionTypes.UpdateOpeningHour,
          payload: {
            opening_hour,
          },
        });
      }
    };
  },
  openModal() {
    return {
      type: AppActionTypes.BookingModalOpened,
    };
  },
  closeModal() {
    return {
      type: AppActionTypes.BookingModalClosed,
    };
  },
  updateFilters(filters: IFilters) {
    return {
      type: AppActionTypes.UpdateFilters,
      payload: {
        filters,
      },
    };
  },
  resetFilters() {
    return {
      type: AppActionTypes.ResetFilters,
      payload: {
        filters: filtersInitialState,
      },
    };
  },
  updateSearch(search: ISearchReservations) {
    return {
      type: AppActionTypes.UpdateSearch,
      payload: {
        search,
      },
    };
  },
  updateSearchField(data: UpdateSearchFieldPayload) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: AppActionTypes.UpdateSearchField,
        payload: data,
      });
    };
  },
  updateSearchTime(time: ISearchTime) {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: AppActionTypes.UpdateSearchTime,
        payload: time,
      });
    };
  },
  resetSearch() {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: AppActionTypes.ResetSearch,
        payload: {
          search: searchReservationsInitialState,
        },
      });
      dispatch({
        type: AppActionTypes.ResetSearchedReservations,
        payload: searchedReservationsInitialState,
      });
    };
  },
  searchReservations(data: ISearchReservationsData) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: LoadingActionTypes.SearchReservationsPending });
      try {
        const response = await searchReservationsCall(prepareSearchReservationsData(data));
        dispatch({
          type: AppActionTypes.UpdateSearchedReservations,
          payload: response,
        });

        dispatch({ type: LoadingActionTypes.SearchReservationsSuccess });
      } catch (error) {
        dispatch({ type: LoadingActionTypes.SearchReservationsRejected });
        throw error;
      }
    };
  },
  loadMoreSearchedReservations(data: ISearchReservationsData) {
    return async (dispatch: AppDispatch) => {
      dispatch({ type: LoadingActionTypes.SearchReservationsLoadMorePending });
      try {
        const response = await searchReservationsCall(prepareSearchReservationsData(data));
        dispatch({
          type: AppActionTypes.LoadMoreSearchedReservations,
          payload: response,
        });
        dispatch({ type: LoadingActionTypes.SearchReservationsLoadMoreSuccess });
      } catch (error) {
        dispatch({ type: LoadingActionTypes.SearchReservationsLoadMoreRejected });
        throw error;
      }
    };
  },
  setCurrentPage(page: number) {
    return {
      type: AppActionTypes.SetCurrentPage,
      payload: page,
    };
  },
};

export default appActions;
