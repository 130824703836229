import { Origin, ReservationState, Statuses } from 'types/reservation';
import moment from 'moment-timezone';

export const reservationInitialState: ReservationState = {
  status: Statuses.Confirmed,
  numberOfGuests: 0,
  tables: [],
  startTime: moment(),
  name: '',
  email: '',
  phoneNumber: '',
  company: '',
  notifications: {
    sms: false,
    email: true,
    resend: false,
    tableReady: false,
    language: '',
  },
  origin: Origin.Online,
  comments: [],
  newComment: '',
  tags: [],
  clientDetails: '',
  clientTags: [],
  specialOffer: { nid: '_none' },
  waiter: { tid: '_none' },
  originalWaiter: { tid: '_none' },
  specialOffers: [],
  showInitialTimeBadge: false,
  autoArrivalDisabled: false,
  timeFormat: '',
  clientIsVip: false,
  clientIsBigSpender: false,
  showBigSpenderTag: false,
  guestDetails: {
    arrivedCount: 0,
    lastVisit: '',
    cancelledCount: 0,
    noShowCount: 0,
  },
  reservationSavingErrors: [],
  payments: {
    currencies: {},
    payments: [],
  },
  dinningRooms: [],
  lateCancellationNoShow: {
    enabled: 0,
    time: 0,
    maxAmount: 0,
    currency: '',
    chargeMode: '',
    paymentData: {
      id: '',
      object: '',
      cancellation_reason: null,
      client_secret: '',
      created: 0,
      description: null,
      last_setup_error: '',
      livemode: false,
      next_action: null,
      payment_method: '',
      payment_method_types: [],
      status: '',
      usage: '',
      paymentSuccess: false,
      paidAmount: 0,
      paidAt: '',
    },
  },
};
