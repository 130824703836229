import { initialState } from 'redux/reducers/index';
import { RestaurantAction, RestaurantActionTypes, RestaurantState } from 'types/app/restaurant';

export const restaurantReducer = (
  state: RestaurantState = initialState.app.settings.restaurant,
  action: RestaurantAction,
): RestaurantState => {
  switch (action.type) {
    case RestaurantActionTypes.Loaded:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
