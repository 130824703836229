import { initialState } from 'redux/reducers/index';
import {
  SpecialOffersAction,
  SpecialOffersActionTypes,
  SpecialOffersState,
} from 'types/app/specialOffers';
import { unionBy } from 'lodash';

export const specialOffersReducer = (
  state: SpecialOffersState = initialState.app.special_offers,
  action: SpecialOffersAction,
): SpecialOffersState => {
  switch (action.type) {
    case SpecialOffersActionTypes.UpdateSpecialOffers:
      return unionBy(action.payload, state, 'id');
    default:
      return state;
  }
};
